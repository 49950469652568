import React, { ReactElement } from "react";
import colors from "@styles/variables/businessphonepricesVariables.module.scss";
import styles from "./styles.module.scss";

import TrustIcon from "../icons/trustIcon";
import FreeTrialIcon from "../icons/freeTrialIcon";
import EnjoyClearerIcon from "../icons/enjoyClearerIcon";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Enjoy Clearer, Better Hearing ",
        description:
            "Join 1000's of others who have transformed their lives by treating their hearing loss.",
        icon: <EnjoyClearerIcon />,
    },
    {
        title: "Free Hearing Trial",
        description: "Start your free, no obligation hearing aid trial.",
        icon: <FreeTrialIcon />,
    },
    {
        title: "Trust Your Specialist",
        description:
            "Choose from highly trained hearing specialists, all with a proven record of best practice standards.",
        icon: <TrustIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle={
                <>
                    Discover the Right <strong>Hearing Aid</strong> for You!
                </>
            }
            classes={{
                mainTitleClasses: "text-center text-xl lg:text-2xl mb-14",
                mainSectionClasses: "mb-4",
                innerSectionClasses: "flex  gap-6 flex-col lg:flex-row",
                oneBoxClasses: `w-full text-center mb-12 lg:mb-20 ${styles["itemWrapper"]} `,
                iconClasses: "children:w-12 children:h-12 children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                iconColor: "#52c9d1",
                descriptionColor: colors.bankVault,
            }}
        />
    );
}
