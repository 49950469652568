import React, { ReactElement } from "react";

export default function EnjoyClearerIcon(): ReactElement {
    return (
        <svg
            width="72"
            height="100"
            viewBox="0 0 72 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_9_72)">
                <path
                    d="M71.2963 35.7756C70.3621 39.881 69.7944 44.1137 68.4208 48.0657C64.9275 58.1148 61.1449 68.0779 57.3383 78.0181C54.1757 86.2714 49.1674 93.1195 41.178 97.3076C33.8477 101.149 26.6044 100.984 19.6928 96.1409C18.1528 95.0623 16.739 93.7148 18.0267 91.747C19.3514 89.7226 20.9969 90.5563 22.5858 91.7166C27.9802 95.6533 33.6237 95.5063 39.2454 92.3478C45.5305 88.816 49.6198 83.3066 52.1669 76.6957C56.1366 66.3952 60.0236 56.0599 63.7279 45.6614C68.9863 30.9051 62.1052 14.601 48.0624 8.09135C33.9793 1.56103 17.2186 6.80597 9.19446 20.2432C8.79097 20.918 8.52452 21.8997 7.92962 22.1838C6.98234 22.6355 5.64025 23.0284 4.79194 22.6703C4.13069 22.3906 3.61623 20.9115 3.6717 20.0048C3.73369 19.009 4.45041 18.0142 5.00616 17.0945C13.2381 3.46788 29.5855 -2.97973 44.9312 1.32049C60.2085 5.60003 70.7362 19.6076 71.2963 35.7756Z"
                    fill="#B5B5B5"
                />
                <path
                    d="M20.1561 26.8351C21.4612 27.3793 22.5488 27.7983 23.6233 28.2783C36.6558 34.1196 41.2421 49.6183 33.5062 61.6842C32.9787 62.5081 32.3185 63.2558 31.6638 63.9872C30.4816 65.3063 29.0536 65.9866 27.5614 64.6217C26.1084 63.2961 26.5119 61.9073 27.7322 60.4935C31.0199 56.6841 31.8933 52.0574 31.7062 47.2282C31.468 41.0625 28.3347 36.6262 22.9164 33.841C21.1763 32.9475 19.3034 32.2705 17.4273 31.7045C12.6202 30.2537 12.1374 29.0924 14.8107 24.9043C20.2312 16.4148 30.0237 12.2866 39.291 14.5776C48.7366 16.9133 56.3877 26.0155 56.8782 36.3367C57.0697 40.3746 56.4682 44.6324 55.3023 48.5114C53.2359 55.3889 50.5039 62.0716 47.9644 68.8022C45.9318 74.1887 42.8093 78.7914 37.788 81.8444C32.5872 85.0072 26.9252 84.7014 22.6423 81.1173C21.2818 79.9789 20.0408 78.688 21.3851 76.8726C22.7065 75.0877 24.1943 75.7298 25.7289 76.853C29.108 79.3258 32.6024 79.6404 35.7042 76.7594C38.5058 74.1549 41.117 70.9333 42.6581 67.4689C45.7773 60.4608 48.2276 53.1447 50.7432 45.8819C53.5774 37.7015 51.0695 28.5253 44.8083 23.2292C37.13 16.7337 25.4722 18.3239 20.1561 26.8351Z"
                    fill="#B5B5B5"
                />
                <path
                    d="M12.4441 44.4512C13.3446 43.8407 14.1147 43.2301 14.963 42.7599C17.5025 41.3537 20.6467 41.8511 22.8642 43.9495C24.9034 45.8781 25.4614 48.7613 24.0921 51.5682C21.8082 56.2483 17.6036 58.8604 13.2261 61.1906C12.6953 61.4736 11.6089 61.2592 11.0607 60.875C8.3331 58.9605 5.46188 57.1571 3.08552 54.854C-0.550263 51.3309 -0.910251 47.1613 1.67166 44.325C4.47436 41.2448 7.90782 41.2296 12.4441 44.4512Z"
                    fill="#F09027"
                />
            </g>
            <defs>
                <clipPath id="clip0_9_72">
                    <rect width="71.2963" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
