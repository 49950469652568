import React, { ReactElement } from "react";

export default function FreeTrialIcon(): ReactElement {
    return (
        <svg
            width="93"
            height="100"
            viewBox="0 0 93 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M53.8835 41.2344C54.0502 41.1486 53.2531 37.5571 53.1893 37.1796C52.944 35.792 52.3774 34.1004 52.4682 32.708C54.607 33.1174 56.9299 34.8825 58.6174 36.1769C61.9753 38.7485 64.8084 41.7688 66.5499 45.6815C68.468 49.9888 67.8131 53.4847 64.5018 56.8335C62.4978 58.8584 60.0867 60.2656 57.6068 61.6458C55.1859 62.9844 52.8116 64.4038 50.4078 65.7717C49.5861 66.2424 45.5757 69.1328 45.4113 66.7867C45.301 65.2496 46.5347 64.2371 47.4398 63.1658C48.654 61.7292 50.0276 60.4078 51.1044 58.878C52.7281 56.5736 52.9317 53.9358 52.1787 51.2783C51.5851 49.1872 50.0374 48.2948 47.6483 48.4419C45.9461 48.5449 44.7589 49.6334 44.4351 51.6657C44.2653 52.9108 44.2218 54.1699 44.3051 55.4239C44.4621 58.8928 44.104 62.2146 42.1908 65.2545C41.8344 65.8384 41.6053 66.4908 41.5182 67.1692C41.4311 67.8476 41.4881 68.5368 41.6855 69.1916C41.9994 70.3414 42.7893 70.969 43.9396 71.1529C45.7842 71.4422 47.4644 70.9077 48.8993 69.8241C52.2915 67.2819 56.0591 65.4629 59.8905 63.6953C61.7102 62.8241 63.4361 61.7694 65.0414 60.5476C67.1558 58.9908 69.1107 57.1865 70.1433 54.7007C72.005 50.2168 71.2986 45.8457 68.8041 41.8816C66.1452 37.6576 62.8437 33.9092 58.5021 31.2787C57.4719 30.6536 56.3927 30.1167 55.3134 29.597C54.6757 29.2906 52.424 28.6728 52.4191 27.8442C52.3847 22.6248 54.5015 17.5257 59.0417 14.7309C64.8035 11.1909 72.4097 13.1742 76.4569 19.0382C78.3137 21.7349 79.0618 24.8263 79.6211 27.9496C81.2502 36.8644 80.3146 46.0584 76.9229 54.4628C74.036 61.7488 69.3437 67.5981 62.6769 71.805L62.7823 72.0207C64.1216 71.3196 65.5124 70.6993 66.7903 69.8977C73.8618 65.4849 78.5418 59.1109 81.446 51.4058C85.2405 41.3349 85.6158 31.1292 82.2947 20.8573C79.7854 13.1154 71.3844 7.96225 63.5255 9.2444C56.8465 10.3353 51.8353 15.3732 50.6506 22.2522C49.9393 26.3781 50.5942 30.4428 51.5508 34.4437C52.1076 36.7751 52.9563 39.0378 53.6725 41.3325L53.8835 41.2344Z"
                fill="#B5B5B5"
            />
            <path
                d="M44.3615 9.05027C47.4153 7.54748 50.0889 6.09127 52.8754 4.88267C56.9839 3.11757 61.3622 2.49243 65.7993 2.67629C71.669 2.90674 76.3171 5.77012 80.1877 9.9941C84.1122 14.2867 86.1947 19.4864 87.0041 25.1617C88.5862 36.272 86.8005 47.0098 82.8196 57.392C80.4207 63.6483 76.4226 68.9607 71.9535 73.8957C68.7109 77.4774 65.2327 80.8482 62.0293 84.4642C59.5078 87.3104 57.1237 90.3062 54.8989 93.3829C52.3603 96.8738 47.6385 97.9844 43.3951 96.2953C38.5042 94.3488 36.6425 90.5783 36.5811 85.5944C36.5321 81.694 36.4315 77.7936 36.353 73.8932C36.356 73.8042 36.34 73.7156 36.3062 73.6332C36.2724 73.5508 36.2215 73.4765 36.1568 73.4152C36.0808 75.0283 36.0415 76.6438 35.9115 78.2545C35.6981 81.059 35.4896 83.866 35.1757 86.6632C34.6851 91.022 36.5026 94.3586 39.7993 96.9376C44.0402 100.257 48.7227 100.946 53.6823 98.6708C54.6831 98.2099 55.7206 97.5627 56.405 96.7268C62.1765 89.6467 68.9463 83.5302 75.2747 76.9895C79.9228 72.182 83.8767 66.8426 86.7294 60.7481C89.2092 55.4504 90.4749 49.8094 91.407 44.0777C92.317 38.4956 92.6015 32.8791 91.8289 27.2578C90.7962 19.7512 88.412 12.8158 82.8318 7.38079C77.9752 2.65179 72.2552 -0.312106 65.3088 0.0262053C59.1816 0.283092 53.2472 2.24064 48.1708 5.67942C47.018 6.45655 45.8798 7.25574 44.7687 8.09171C44.5823 8.25351 44.5381 8.63106 44.3615 9.05027Z"
                fill="#B5B5B5"
            />
            <path
                d="M38.6955 34.8505C37.8484 29.6131 35.8156 24.6383 32.7522 20.3056C31.5062 18.5258 28.9602 18.538 27.8466 20.3473C27.1254 21.5314 27.2873 22.6811 28.0673 23.8235C32.3524 30.097 34.0424 37.0348 32.973 44.5439C31.7956 52.8791 27.7116 59.5251 20.8977 64.4846C19.4775 65.5191 19.139 67.3382 20.125 68.6816C21.1111 70.025 22.9041 70.2506 24.3096 69.2307C32.1022 63.5922 36.8338 56.0146 38.5998 46.5786C39.2925 42.7026 39.3248 38.7373 38.6955 34.8505Z"
                fill="#F09027"
            />
            <path
                d="M28.0403 35.9804C27.4933 32.4649 26.0559 29.2681 23.9955 26.3655C23.6543 25.8709 23.1684 25.4939 22.6045 25.2862C22.0405 25.0784 21.426 25.05 20.8453 25.2049C20.2645 25.3598 19.7459 25.6904 19.3605 26.1514C18.9751 26.6124 18.7418 27.1813 18.6925 27.78C18.6189 28.5866 18.9378 29.2656 19.3621 29.9374C23.532 36.4511 23.7503 43.1339 20.098 49.9198C18.7931 52.3443 16.8897 54.2688 14.66 55.8843C14.176 56.2038 13.8063 56.6689 13.6043 57.2123C13.4024 57.7557 13.3787 58.3493 13.5367 58.9071C13.8212 60.1181 14.6282 60.8242 15.8325 61.0865C16.8701 61.312 17.7163 60.9173 18.4987 60.302C26.4116 54.053 29.5905 45.9213 28.0403 35.9804Z"
                fill="#F09027"
            />
            <path
                d="M17.7826 43.0036C18.3909 39.1841 17.6746 35.7005 15.5211 32.5405C14.5399 31.0843 12.7714 30.719 11.4126 31.631C10.0537 32.5429 9.71026 34.2713 10.6595 35.7692C13.2571 39.8706 12.4648 44.3005 8.5967 47.3159C8.22126 47.5789 7.91591 47.9298 7.70727 48.3379C7.49864 48.746 7.39305 49.1989 7.39971 49.6571C7.41688 50.797 7.91481 51.7016 8.93765 52.2263C9.40847 52.4966 9.94798 52.6236 10.49 52.5918C11.032 52.56 11.553 52.3708 11.989 52.0473C15.1629 49.8066 17.0565 46.7496 17.7826 43.0036Z"
                fill="#F09027"
            />
            <path
                d="M4.4808 36.713C3.72882 36.5884 2.95674 36.6896 2.26237 37.0039C1.56801 37.3181 0.98257 37.8313 0.580209 38.4784C0.177848 39.1254 -0.0233187 39.8773 0.00215133 40.6387C0.0276214 41.4001 0.278598 42.1368 0.723301 42.7555C1.168 43.3743 1.78641 43.8472 2.50023 44.1144C3.21405 44.3817 3.99117 44.4311 4.73315 44.2566C5.47512 44.0821 6.14857 43.6914 6.66823 43.134C7.18789 42.5767 7.53037 41.8777 7.6523 41.1257C7.80932 40.1213 7.56472 39.0955 6.9713 38.2698C6.37788 37.4441 5.48329 36.8849 4.4808 36.713V36.713Z"
                fill="#F09027"
            />
        </svg>
    );
}
